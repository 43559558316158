import React from "react"
import Formbox from "./Formbox"

import { FiPhoneCall, FiMail, FiMapPin } from "react-icons/fi"

const Contact = () => (
  <div className="contact">
    <div className="contact__double">
      <div className="contact__infobox">
        <ul>
          <li>NIP: 847-159-91-82</li>
          <li>
            <h4>EQTHERM - biuro</h4>
          </li>
          <li>
            <FiMail /> <a href="mailto:biuro@eqtherm.pl">biuro@eqtherm.pl</a>
          </li>
          <li>
            <FiPhoneCall /> 575-505-250
          </li>
          <li>
            <FiMapPin /> Przasnyska 6A/352A
          </li>
          <li style={{ transform: "translateX(1.3rem)" }}>01-756 Warszawa</li>
          <li>
            <h4>Szymon Milewski</h4>
          </li>
          <li>
            <FiMail />{" "}
            <a href="mailto:szmilewski@eqtherm.pl">szmilewski@eqtherm.pl</a>
          </li>
          <li>
            <FiPhoneCall /> 507-304-435
          </li>
        </ul>
      </div>
      <Formbox />
    </div>
    <div className="contact__map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4885.123029587592!2d20.96498308642602!3d52.26027549213288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecb96cf03d6ff%3A0x8819b261a50b7dc3!2sPrzasnyska+6A%2C+01-756+Warszawa!5e0!3m2!1spl!2spl!4v1565261263681!5m2!1spl!2spl"
        width="1140"
        height="450"
        frameBorder="0"
        allowFullScreen
        title="Biuro EQTHERM na mapie Warszawy"
      />
    </div>
  </div>
)

export default Contact
