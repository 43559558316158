import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import Container from "../components/Container/Container"
import Contact from "../components/Contact/Contact"

const Kontakt = () => (
  <Layout pageInfo={{ pageName: "kontakt" }}>
    <SEO title="Kontakt" />
    <Container>
      <Contact />
    </Container>
  </Layout>
)

export default Kontakt
