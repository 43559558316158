import React, { useState, useRef } from "react"
import MediaQuery from "react-responsive"
import ReCAPTCHA from "react-google-recaptcha"


const Formbox = () => {
  const [formState, setFormState] = useState("")
  const [formMessage, setFormMessage] = useState("")
  const [verified, setVerified] = useState(false)
  const recaptchaRef = useRef()
  
  const onVerify = () => {
    setVerified(true)
  }

  const onExpire = () => {
    setVerified(false)
  }

  const onSend = e => {
    e.preventDefault()
    if (verified) {
      const formData = new FormData(e.target)
      fetch("/.netlify/functions/send", {
        method: "POST",
        body: JSON.stringify(Object.fromEntries(formData)),
        headers: {
          "content-type": "application/json",
        },
      })
        .then(res => res.json())
        .then(response =>
          response.status === "success"
            ? handleSuccess()
            : handleFailure(response)
        )
        .catch(response => this.handleFailure(response))
    } else {
      setFormState("--fail")
      setFormMessage("Brak weryfikacji reCAPTCHA")
    }
  }
  const handleSuccess = () => {
    setFormState("--success")
    setFormMessage("Wiadomość wysłana")
    recaptchaRef.current.reset()
  }

  const handleFailure = response => {
    let messageToSet = ""
    switch (response.message) {
      case "Couldn't verify CAPTCHA server-side":
        messageToSet = "Nie udało się zweryfikować CAPTCHA po stronie serwera"
        break
      default:
        messageToSet =
          "Nie udało się wysłać wiadomości. Czy wpisany adres e-mail jest poprawny?"
        break
    }
    setFormState("--fail")
    setFormMessage(messageToSet)
  }

  return (
    <div className="contact__formbox">
      <h4>Napisz do nas</h4>
      <form
        className="contact__form"
        id="contact__form"
        acceptCharset="utf-8"
        method="post"
        onSubmit={onSend}
      >
        <label htmlFor="name" className="contact__label">
          Imię i nazwisko
        </label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Jan Kowalski"
          required
          className="contact__input"
        />

        <label htmlFor="email" className="contact__label">
          Adres e-mail
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="jan.kowalski@domena.pl"
          required
          className="contact__input"
        />

        <label htmlFor="message" className="contact__label">
          Treść wiadomości
        </label>
        <textarea
          name="message"
          id="message"
          placeholder="Treść wiadomości..."
          required
          className="contact__textarea"
        ></textarea>
        <MediaQuery maxDeviceWidth={374}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LcVzdcUAAAAALRyN5A58HlwAyOKSqcCzLnc6i7t"
            onChange={onVerify}
            onExpired={onExpire}
            size="compact"
          />
        </MediaQuery>
        <MediaQuery minDeviceWidth={375}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LcVzdcUAAAAALRyN5A58HlwAyOKSqcCzLnc6i7t"
            onChange={onVerify}
            onExpired={onExpire}
          />
        </MediaQuery>
        <input type="submit" value="Wyślij" className="contact__submit" />
        <span id="contact-state" className={"contact-state" + formState}>
          {formMessage ? formMessage : ""}
        </span>
      </form>
    </div>
  )
}

export default Formbox
